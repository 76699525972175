<template>
  <div :class="`replay_main_view ${replay_layout} ${orientation != 90 && orientation != -90 ? 'enable_scroll' : ''}`">
    <!-- PLAYER -->
    <UploadProgression :progression="uploads[0]?.progress || null" />
    <div>
      <div class="video_container center">

        <span class="currentTakeIndex">{{ currentTakeIndex + 1 }}</span>

        <Player class="videoPlayer" :src="currentObjectUrl" :media_url="currentMediaUrl" ref="replay" @ready="videoLoaded = true" @error="videoError = true" />
      </div>

      <input ref="file_input" style="display: none;" type="file" accept="video/*" @change="uploadAFile" />

      <!-- BUTTONS -->
      <div class="choice_buttons center">
        <p class="button retake" @click="retake()">
          {{ $t("Replay.redo") }}
        </p>
        <p class="button validate" :class="videoLoaded == false || takeSelectedValidated ? 'disable' : ''" @click="validateTake(localRecords[currentTakeIndex])">
          <a v-if="takeSelectedValidated">{{ $t("Replay.validated") }}<img src="../../assets/checked_white.svg" /></a>
          <span v-else-if="takeValidating == true" class="loading_spinner center"></span>
          <a v-else-if="takeValidating == false">{{ $t("Replay.validate") }}</a>
        </p>
      </div>
    </div>

    <!-- TAKES CHOICE -->
    <div class="takes_container_scroller">
      <div class="takes center" v-if="localRecords.length > 0">
        <div class="take center" :class="currentTakeIndex == takeIndex ? 'active' : ''" v-for="(take, takeIndex) in localRecords" :key="take" @click="selectTake(take, takeIndex)">
          <div class="takeThumbnail">
            <p class="index">{{ takeIndex + 1 }}</p>
            <img v-if="take.thumbnail != undefined" :src="take.thumbnail" class="thumbnail" />
            <div v-else-if="take.uploading != true" class="thumbnail_placeholder_containter">
              <img class="thumbnail_placeholder" src="../../assets/cloud.svg" />
            </div>
          </div>
          <div class="takeInfos">
            <p>{{ take.name }}</p>
            <p v-if="take.duration != null">{{ $t('Replay.duration') }} : {{ renderSeconds(Math.floor(take.duration/1000)) }}</p>
            <img v-if="download_loading != takeIndex" src="../../assets/download.svg" class="download" @click.stop="downloadTake(take, takeIndex)" />
            <span v-if="download_loading == takeIndex" class="downloadLoading loading_spinner center" style="--color:var(--color2)"></span>
            <img src="../../assets/checked_blue.svg" v-if="take.validated" class="validated" />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//NPM
import axios from "axios";
import Vuex from "vuex";
import { parse as parseCookie } from 'cookie-parse';

import UploadProgression from "./uploadProgression.vue";
import { ms2tc, renderSeconds } from "../../lib/time"


export default {
  data() {
    return {
      socketHandled: this.$store.state.socketInstance,
      currentTakeIndex: -1,
      currentObjectUrl: null,
      currentMediaUrl: null,
      currentType: "",
      videoLoaded: false,
      videoError: false,
      orientation: (window.innerHeight > window.innerWidth ? 0 : 90),
      download_loading: -1,
      takeValidating: false
    };
  },
  components: {
    UploadProgression
  },
  async mounted() {
    if (this.project_id == null) {
      await this.emit_discovering_socket_event();
    }

    if (this.localRecords.length == 0) {
      this.$router.push({
        path: "/",
        query: {
          ...this.$route.query,
        },
      });
    } else {
      this.selectTake(
        this.localRecords[this.localRecords.length - 1],
        this.localRecords.length - 1
      );
    }

    //ORIENTATION DETECTION
    window.screen.orientation.addEventListener("change", () => {
      this.orientation = window.screen.orientation.angle;
    });
  },
  computed: {
    replay_layout() {
      return this.orientation == 90 || this.orientation == -90
        ? "horizontal"
        : "";
    },
    ...Vuex.mapGetters([
      "currentClipId",
      "project_timeline",
      "creativeSpaceUrl",
      "project_id",
      "uploads",
    ]),
    localRecords: {
      get() {
        return this.$store.state.localRecords
      },
      set(value) {
        this.$store.state.localRecords = value
      }
    },
    currentClip() {
      let index = this.project_timeline.findIndex(clip => clip._id === this.currentClipId)
      if (index != -1) {
        return {
          clipIndex: index,
          ...this.project_timeline[index]
        }
      } else {
        return null
      }
    },
    takeSelectedValidated(){
      return this.localRecords[this.currentTakeIndex] != undefined && this.localRecords[this.currentTakeIndex].validated ? true : false
    }
  },
  methods: {
    ...Vuex.mapActions(["uploadTake", "emit_discovering_socket_event"]),
    ms2tc,
    renderSeconds,
    //SETTINGS UP REPLAY PLAYERS
    createPlayer(record) {
      //REVOKE OLD OBJECT URL (drain memory)
      if (this.currentObjectUrl != null) {
        try {
          URL.revokeObjectURL(this.currentObjectUrl);
        } catch (e) {
          console.warn("imposible to revoke object url");
        }
      }

      this.currentObjectUrl = null
      this.currentMediaUrl = null

      if (record.url == undefined) {
        this.currentObjectUrl = window.URL.createObjectURL(record.record_blob_file)
      } else {
        this.currentMediaUrl = record.url
      }

    },

    selectTake(record, takeIndex) {
      this.videoLoaded = false;
      this.videoError = false;
      this.createPlayer(record);
      this.currentTakeIndex = takeIndex;
    },

    async downloadTake(take, takeIndex) {
      this.download_loading = takeIndex;
      const link = document.createElement('a');

      let url = null;

      if (take.record_blob_file != null) {
        let record_blob_file_url = window.URL.createObjectURL(take.record_blob_file)
        url = await axios({
          url: record_blob_file_url,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          URL.revokeObjectURL(record_blob_file_url);
          return window.URL.createObjectURL(new Blob([response.data]));
        })
          .catch(() => { })
      } else if (take.url != null) {
        //RETRIEVE MEDIA ID IN VAR CURRENT MEDIA URL
        let media_url_splitted = take.url.split('/').filter(e => e != undefined && e != "")
        let medias_endpoint_url_index = media_url_splitted.indexOf("medias")
        let media_id = media_url_splitted[medias_endpoint_url_index + 1]

        url = await new Promise((resolve) => {
          let urlToAuthenticate = `/medias/${media_id}`
          this.socketHandled.emit("createShortLivedTokenUrl", urlToAuthenticate, (url) => {
            resolve(url)
          })
        }).then((media_stream_url) => {
          return axios({
            url: media_stream_url,
            method: 'GET',
            responseType: 'blob',
          })
        }).then((response) => {
          return window.URL.createObjectURL(new Blob([response.data]));
        })
          .catch(() => { })
      }

      if (url == null) {
        this.Toaster.error("Unable to download take");
        return;
      }

      link.href = url;

      //FILENAME
      var extention = "mp4";
      if (take.file != undefined && take.file.type) {
        extention = this.detectExtention(take.file.type);
      }

      let prefix;
      if (this.currentClip != null) {
        prefix = `clip_${this.currentClip.clipIndex + 1}`
        if (this.currentClip.scrpt_text != "" && this.currentClip.scrpt_text != null) {
          prefix = prefix + "-" + this.currentClip.scrpt_text.filenameCompliant().slice(0, 10);
        }
      }

      link.setAttribute('download', `${prefix}-take_${takeIndex + 1}.${extention}`); //or any other extension

      document.body.appendChild(link);
      link.click();
      this.download_loading = -1;
    },

    uploadAFile(evt) {
      //GET CLIP ID
      const clip_id = this.localRecords[0].clip_id

      let file = evt.target.files[0]

      let newTake = {
        clip_id: clip_id,
        mimeType: file.type,
        media_type: "video_record"
        //recordedBlobs : []
      }

      function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }

      var reader = new FileReader();
      reader.onload = () => {
        var blob = dataURLtoBlob(reader.result);
        newTake.record_blob_file = new Blob([blob], {
          type: file.type
        });
        console.log(newTake)
        this.validateTake(newTake)
      };

      reader.readAsDataURL(file);
    },

    retake() {
      this.$router.push({
        path: "/",
        query: {
          ...this.$route.query,
        },
      });
    },

    async validateTake(take) {
      try {
        this.takeValidating = true

        //If take is not a blob recorded 
        if (take.record_blob_file == undefined) {
          this.localRecords = [];
        } else {
          await this.uploadTake(take)
        }

        //Redirect to 
        var parsedCookie = parseCookie(document.cookie) || {}
        if (parsedCookie.token != undefined && parsedCookie.token != null) {
          console.log('Redirection to app yopbox')
          window.location.href = `${this.creativeSpaceUrl}?clip_id=${take.clip_id}`
        }
        this.takeValidating = false
      } catch (e) {
        this.takeValidating = false
        console.error(e)
      }
    }
  },

  beforeUnmount() {
    //REVOKE OLD OBJECT URL (drain memory)
    if (this.currentObjectUrl != null) {
      URL.revokeObjectURL(this.currentObjectUrl);
    }
  },
};
</script>

<style src="./replays.css" scoped></style>