<template>
    <div class="uploadProgression" :style="'--progression:'+progression+'%'" :class="{'active' : progression != null}">
        <span></span>
        <p>{{ $t('Replay.uploadingTake') }} : <a>{{progression}}%</a></p>
    </div>
</template>

<script>
export default {
    props : ["progression"]
}
</script>


<style scopped>
    .uploadProgression{
        width:100vw !important;
        height: 40px;
        position: absolute;
        background-color: #3eb1c89e;
        /*
        background: linear-gradient(158deg,#3d3393,#2b76b9 31%,#2cacd1 57%,#35eb93) border-box;
        */
        background-color: #3d545c9e;
        z-index: 6;
        --progression : 30%;
        top: -40px;
        transition: top 400ms ease-in-out;
    }

    .uploadProgression.active{
        top: 0px;
    }


    .uploadProgression > span{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--color2);
        transition: width 1s ease-in-out;
        background: linear-gradient(158deg,#3d3393,#2b76b9 31%,#2cacd1 57%,#35eb93) border-box;
        clip-path: polygon(0 0, var(--progression) 0%, var(--progression) 100%, 0% 100%);
    }

    .uploadProgression > p{
        color: white;
        position: absolute;
        margin: 0;
        margin-top: 8px;
        margin-left: 10px;
    }

    .uploadProgression > p > a{
        font-weight: 500;
    }
</style>